.App {
  text-align: center;
  background-color: #f6f6f4;
  min-height: 100vh;
  margin: 40px;
}
.css-17z57jz-MuiDataGrid-root {
  background-color: white;
}
.css-99lfi7-MuiDataGrid-columnsContainer {
  background-color: #676f8e;
  color: white;
}
.css-17z57jz-MuiDataGrid-root .MuiDataGrid-iconSeparator {
  color: #676f8e !important;
}
.whiteText{
  color: white;
  padding: 10px;
  padding-left: 10px;
  text-align: left;
}
#panel {
  padding-top: 30px;
  background-color: #5d4e88;
}
#entire-table {
  min-height: 80vh;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.css-1uzrzkv-MuiGrid-root {
  padding: 1.5em;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
